import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Main } from '../components/layout/Main.jsx';
import { Config } from '../../../common/config.mjs';

export function setTitle(title) {
    document.title = title + ' - ' + Config.webSiteName;
}
export async function renderApp(App) {
    const root = createRoot(document.getElementById('app'));
    root.render(
        <StrictMode>
            <Main content={<App />} />
        </StrictMode>
    );
}

export function clone(v) {
    return JSON.parse(JSON.stringify(v));
}

export function convertJsxToNode(jsx) {
    const container = document.createElement('div');
    const root = createRoot(container);
    root.render(jsx);
    return container.firstChild;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
